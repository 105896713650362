import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout.js';
import Login from './pages/login/Login.js';
import Empty from "./pages/empty/Empty";
import Utilisateurs from "./pages/config/utilisateurs/Utilisateurs.js";
import Profil from "./pages/config/utilisateurs/Profil.js";
import Entreprises from "./pages/config/entreprises/Entreprises.js";
import Agences from "./pages/config/agences/Agences.js";
import Fonctions from "./pages/config/fonctions/Fonctions.js";
import TypeCaisses from "./pages/config/typeCaisses/TypeCaisses.js";
import Caisses from "./pages/config/caisses/Caisses.js";
import Natures from "./pages/config/depenses/Natures.js";
import Rubriques from "./pages/config/depenses/Rubriques.js";
import Depenses from "./pages/depenses/Depenses.js";
import Decharges from "./pages/decharges/Decharges.js";
import Transferts from "./pages/transferts/Transferts.js";

import Unauthorized from './pages/Errors/Unauthorized';
import NotFound from './pages/Errors/NotFound';

import ProtectedRoute from './services/Securite/ProtectedRoute';
import ProtectedRouteSuperAdmin from './services/Securite/ProtectedRouteSuperAdmin';

function App() {
  return (
    <>
      <BrowserRouter>
        {/* basename="/financeapp" : Pour installer l'application en local */}
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route element={<Layout />} >
              <Route path="/" element={<ProtectedRoute allowedRoles={['SuperAdmin','Admin','Manager','ResponsableRegional','DirecteurRegional','ChefStation','Financier','Caissier','AgentSuivi','Employe', 'Approbateur']} />}>
                <Route path="/empty" element={<Empty />} />
                <Route path="/utilisateurs" element={<Utilisateurs />} />
                <Route path="/profil" element={<Profil />} />
                <Route path="/entreprises" element={<Entreprises />} />
                <Route path="/agences" element={<Agences />} />
                <Route path="/fonctions" element={<Fonctions />} />
                <Route path="/typecaisses" element={<TypeCaisses />} />
                <Route path="/caisses" element={<Caisses />} />
                <Route path="/natures" element={<Natures />} />
                <Route path="/rubriques" element={<Rubriques />} />
                <Route path="/depenses" element={<Depenses />} />
                <Route path="/decharges" element={<Decharges />} />
                <Route path="/transferts" element={<Transferts />} />
              </Route>
            </Route>

            <Route path="unauthorized" element={<Unauthorized />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer style={{ fontSize: '1rem' }} />
    </>
  );
}

export default App;