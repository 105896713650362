import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import Select from "react-select";
import { axiosInstance, createCancelTokenSource, Utilisateur, Caisse, Agence } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const Utilisateurs = () => {
    const { authData } = useContext(AuthContext);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [filtredUtilisateurs, setFiltredUtilisateurs] = useState([]);
    const [caisses, setCaisses] = useState([]);
    const [agences, setAgences] = useState([]);
    const [agencesRegion, setAgencesRegion] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showRegionModal, setShowRegionModal] = useState(false);
    const [showGetModal, setShowGetModal] = useState(false);
    const [showRegionModalUser, setShowRegionModalUser] = useState('');
    // const [idUtilisateurDelete, setIdUtilisateurDelete] = useState('');
    // const [idUtilisateurUpdate, setIdUtilisateurUpdate] = useState('');
    const [idUtilisateurSelect, setIdUtilisateurSelect] = useState('');

    const pathImageSignatureUpdateRef = useRef(null);
    const [pathImageSignatureUpdate, setPathImageSignatureUpdate] = useState(null);
    const [pathImageSignatureName, setPathImageSignatureName] = useState(null);

    const [search, setSearch] = useState('');
    const [enteredAgenceText, setEnteredAgenceText] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    const debouncedAgenceText = useDebounce(enteredAgenceText, 300);

    const [filters, setFilters] = useState({
        statut: '',
        role: '',
        idAgence: ''
    });

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredUtilisateurs].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredUtilisateurs(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = utilisateurs.slice(startIndex, endIndex);

        setFiltredUtilisateurs(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1);
        getUtilisateurs(1, pageSize);
        showHideFiltre(false);
    };

    const showHideFiltre = (val) => {
        setShowFilters(val);
    };

    //Modifier compte
    const formik1 = useFormik({
        initialValues: {
            idUtilisateur: '',
            nom: '',
            prenom: '',
            username: '',
            statut: '' || 'actif',
            fonction: '',
            agence: '',
            role: '',
            idCaisse: '',
            entreprise: '',
            // idAgencesUpdate: [],
            // resetPassword: false,
            // newPassword: ''
            agences: '',
            agencesCount: 1
        },
        validationSchema: Yup.object({
            statut: Yup.string()
                .required('Champ obligatoire'),
            role: Yup.string()
                .required('Champ obligatoire'),
            resetPassword: Yup.bool(),
            // username: Yup.string().test('username-validation', 'Invalid username', function (value) {
            //     if (!value) {
            //         return this.createError({ message: 'Champ obligatoire' });
            //     }
            //     if (value.length < 6 || value.length > 255) {
            //         return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
            //     }

            //     return true;
            // }),
            // newPassword: Yup.string().test('password-validation', 'Invalid password', function (value) {
            //     const resetPassword = this.resolve(Yup.ref('resetPassword'));

            //     if (resetPassword) {
            //         if (!value) {
            //             return this.createError({ message: 'Champ obligatoire' });
            //         }
            //         if (value.length < 6 || value.length > 16) {
            //             return this.createError({ message: 'Le champ doit contenir entre 7 et 16 caractères' });
            //         }
            //     }

            //     return true;
            // }),
            idCaisse: Yup.string().test('idCaisse-validation', 'Invalid idCaisse', function (value) {
                const role = this.resolve(Yup.ref('role'));

                if (role && (role === "Caissier" || role === "ChefStation" || role === "AssistanteDirection")) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                }

                return true;
            }),
            // idAgencesUpdate: Yup.array()
            //     .min(1, "Au moins une agence doit être sélectionnée")
            //     .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idUtilisateur: values.idUtilisateur,
                    statut: values.statut,
                    role: values.role,
                    // resetPassword: values.resetPassword,
                    // newPassword: values.newPassword,
                    idCaisse: values.idCaisse === "" ? null : values.idCaisse,
                    idCompteCrud: authData.idUtilisateur,
                    // idAgences: values.idAgencesUpdate?.map(agence => agence.idAgence) || [],
                };

                //console.log(values);

                // const response = await axiosInstance.put(Utilisateur, postData);
                const formData = new FormData();
                formData.append('idUtilisateur', values.idUtilisateur);
                formData.append('statut', values.statut);
                formData.append('role', values.role);
                // // formData.append('idAgences', values.idAgencesUpdate?.map(agence => agence.idAgence) || []);
                // values.idAgencesUpdate?.forEach(agence => {
                //     formData.append('idAgences', agence.idAgence);
                // });

                if (values.role === "Caissier" || values.role === "ChefStation" || values.role === "AssistanteDirection") {
                    formData.append('idCaisse', values.idCaisse);
                }

                // formData.append('username', values.username);
                // formData.append('resetPassword', values.resetPassword);
                // formData.append('newPassword', values.newPassword);

                formData.append('idCompteCrud', authData.idUtilisateur);
                formData.append('signatureFile', pathImageSignatureUpdate);
                formData.append('signatureFileName', pathImageSignatureName);

                //console.log(formData);

                await axiosInstance.put(Utilisateur, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                CustomToast("L'utilisateur a été modifié avec succès", 'success');
                getUtilisateurs(pageNumber, pageSize);
                setShowUpdateModal(false);
                setIdUtilisateurSelect("");
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un compte avec le même nom d'utilisateur existe déjà.") {
                    CustomToast("Un utilisateur avec le même nom d'utilisateur existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Le compte à mettre à jour n'existe pas.") {
                    CustomToast("Le compte à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const handleResetPassword = () => {
        formik1.setFieldValue('resetPassword', !formik1.values.resetPassword);
    };

    // const showHideUpdateModal = (val, idUtilisateur, nom, prenom, username, statut, fonction, agence, role, idCaisse, entreprise, agences) => {
    //     setShowUpdateModal(val);
    //     formik1.resetForm();
    //     getCaisses();
    //     if (val) {
    //         formik1.setValues((prevValues) => ({
    //             ...prevValues,
    //             idUtilisateur: idUtilisateur,
    //             nom: nom || '',
    //             prenom: prenom || '',
    //             username: username || '',
    //             statut: statut || 'actif',
    //             fonction: fonction || '',
    //             agence: agence || '',
    //             role: role || '',
    //             idCaisse: idCaisse || '',
    //             entreprise: entreprise || '',
    //             agences: agences || '',
    //         }));

    //     }
    // };

    const showHideUpdateModal = (val, idUtilisateur, agence, role, idCaisse, entreprise) => {
        setShowUpdateModal(val);
        formik1.resetForm();
        if (val) {
            setIdUtilisateurSelect(idUtilisateur);
            if (role === "Caissier") {
                getCaisses();
            }
            getUtiisateur(idUtilisateur, agence, idCaisse, entreprise);
        }
    };

    const handlePathImageSignatureChangeUpdate0 = (e) => {
        setPathImageSignatureUpdate(e.target.files[0]);
        setPathImageSignatureName('');
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith('image/')) {
            const image = new Image();

            image.onload = () => {
                const widthInCm = image.naturalWidth * 0.0264583333; // Conversion de pixels en centimètres
                const heightInCm = image.naturalHeight * 0.0264583333; // Conversion de pixels en centimètres

                if (widthInCm === 5 && heightInCm === 5) {
                    // Les dimensions de l'image sont correctes, faites quelque chose avec le fichier
                } else {
                    // Les dimensions de l'image ne sont pas correctes, affichez un message d'erreur ou effectuez une autre action
                }
            };

            image.src = URL.createObjectURL(file);
        }
    };

    const [errorMessage, setErrorMessage] = useState('');

    const handlePathImageSignatureChangeUpdate1 = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const img = new Image();
            console.log(file)
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                const maxWidth = 50; // en mm
                const maxHeight = 50; // en mm
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                // Convertir les pixels en mm en supposant 96 DPI (ajuster si nécessaire)
                const widthInMm = width / 3.78;
                const heightInMm = height / 3.78;
                console.log(widthInMm, heightInMm)
                if (widthInMm > maxWidth || heightInMm > maxHeight) {
                    setErrorMessage('Les dimensions de l\'image dépassent la taille autorisée.');
                    setPathImageSignatureUpdate(null);
                    setPathImageSignatureName('');
                } else {
                    setPathImageSignatureUpdate(file);
                    // setImageUrl(URL.createObjectURL(file));
                    setErrorMessage('');
                    setPathImageSignatureName('');
                }
            };
        } else {
            setErrorMessage('Veuillez sélectionner une image valide.');
            setPathImageSignatureUpdate(null);
            setPathImageSignatureName('');
        }
    };

    const handlePathImageSignatureChangeUpdate2 = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const sizeInPixels = 189; // 5 cm = 189 pixels à 96 DPI

                canvas.width = sizeInPixels;
                canvas.height = sizeInPixels;

                ctx.drawImage(img, 0, 0, sizeInPixels, sizeInPixels);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const newFile = new File([blob], file.name, { type: file.type });
                        setPathImageSignatureUpdate(newFile);
                        //setImageUrl(URL.createObjectURL(newFile));
                        setErrorMessage('');
                        setPathImageSignatureName('');
                    }
                }, file.type);
            };
        } else {
            setErrorMessage('Veuillez sélectionner une image valide.');
            setPathImageSignatureUpdate(null);
            setPathImageSignatureName('');
        }
    };

    const handlePathImageSignatureChangeUpdate = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const sizeInPixels = 189; // 5 cm = 189 pixels à 96 DPI

                canvas.width = sizeInPixels;
                canvas.height = sizeInPixels;

                ctx.drawImage(img, 0, 0, sizeInPixels, sizeInPixels);
                canvas.toBlob((blob) => {
                    if (blob) {
                        const newFile = new File([blob], file.name, { type: file.type });
                        setPathImageSignatureUpdate(newFile);
                        //setImageUrl(URL.createObjectURL(newFile));
                        setErrorMessage('');
                        setPathImageSignatureName('');
                    }
                }, file.type);
            };
        } else {
            setErrorMessage('Veuillez sélectionner une image valide.');
            setPathImageSignatureUpdate(null);
            setPathImageSignatureName('');
        }
    };

    //Recuperer les utilisateurs depuis api RH
    const showHideGetModal = (val) => {
        setShowGetModal(val);
        formik2.resetForm();
    };

    const formik2 = useFormik({
        initialValues: {
            admin: false,
            responsableService: false,
            responsableRegional: false,
            directeurRegional: false,
            chefStation: false,
            caissier: false,
            financier: false,

        },
        validationSchema: Yup.object({
            // statut: Yup.string()
            //     .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    admin: values.admin,
                    responsableService: values.responsableService,
                    responsableRegional: values.responsableRegional,
                    directeurRegional: values.directeurRegional,
                    chefStation: values.chefStation,
                    caissier: values.caissier,
                    financier: values.financier,
                    idUtilisateurCrud: authData.idUtilisateur
                };

                const response = await axiosInstance.post(`${Utilisateur}/GetUtilisateurFromApi`, postData);
                CustomToast("Utilisateurs enregistrés avec succès", 'success');
                getUtilisateurs(pageNumber, pageSize);
                setShowGetModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Afficher agences

    const showHideRegionModal = (idUtilisateur, utilisateur) => {
        try {
            setShowRegionModal(true);
            setShowRegionModalUser(utilisateur);
            showHideRegionModal1(idUtilisateur)

        }
        catch (err) {
            console.log(err);
            setShowRegionModal(false);
            setShowRegionModalUser("");
        }
    }

    const showHideRegionModal1 = async (idUtilisateur) => {
        try {

            const queryParams = new URLSearchParams({});

            queryParams.append("idUtilisateurFilter", idUtilisateur);

            const response = await axiosInstance.get(`${Agence}/GetListeAgencesByUsers?${queryParams}`);
            const resp = await response.data;
            setAgencesRegion(resp);
        }
        catch (err) {
            console.log(err);
        }
    }


    //Load data

    const getUtilisateurs = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.role !== '') {
                queryParams.append("role", filters.role);
            }

            if (authData?.role === "ResponsableRegional" || authData?.role === "DirecteurRegional") {
                authData?.idAgencesRegion.forEach(id => queryParams.append('idAgencesRegion', id));
            }

            //const response = await axiosInstance.get(`${Utilisateur}/GetListeUtilisateurs?${queryParams}`);
            const response = await axiosInstance.get(`${Utilisateur}/GetListeUtilisateurs?${queryParams}`, { cancelToken });

            const resp = await response.data;
            setUtilisateurs(resp.data);
            setFiltredUtilisateurs(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getCaisses = async () => {
        try {
            const queryParams = new URLSearchParams({
                idAgence: authData.idAgence
            });
            const response = await axiosInstance.get(`${Caisse}?${queryParams}`);
            const resp = await response.data;
            setCaisses(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getUtiisateur = async (idUtilisateur, agence, idCaisse, entreprise) => {
        try {
            const response = await axiosInstance.get(`${Utilisateur}/GetUtilisateursRegionalById/${idUtilisateur}`);
            const resp = await response.data;
            formik1.setValues((prevValues) => ({
                ...prevValues,
                idUtilisateur: idUtilisateur,
                nom: resp.utilisateur.nom || '',
                prenom: resp.utilisateur.prenom || '',
                username: resp.utilisateur.username || '',
                statut: resp.utilisateur.statut || 'actif',
                fonction: resp.utilisateur.fonction || '',
                agence: agence || '',
                role: resp.utilisateur.role || '',
                idCaisse: idCaisse || '',
                entreprise: entreprise || '',
                // resetPassword: false,
                // idAgencesUpdate: resp.agences || [],
                agences: Array.isArray(resp.agences) ? resp.agences.map(agence => agence.libelle).join('\n') : '',
                agencesCount: Array.isArray(resp.agences) ? resp.agences.length : 1
            }));

            //setAgences(resp.agences || []);
            setPathImageSignatureName(resp.utilisateur.signature || '');
        }
        catch (err) {
            console.log(err);
        }
    }

    const getAgencesUpdate = async (agence) => {
        try {
            const queryParams = new URLSearchParams({});

            if (agence !== '') {
                queryParams.append("text", agence);
            }

            if (idUtilisateurSelect !== '') {
                queryParams.append("idUtilisateurFilter", idUtilisateurSelect);
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocompleteByUsers?${queryParams}`);
            const resp = await response.data;
            setAgences(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getUtilisateurs(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getUtilisateurs(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    useEffect(() => {
        //Load communes
        if (formik1.values.role === "Caissier" || formik1.values.role === "ChefStation" || formik1.values.role === "AssistanteDirection") {
            getCaisses(formik1.values.role);
        } else {
            setCaisses([]);
            formik1.setValues((prevValues) => ({
                ...prevValues,
                idCaisse: ''
            }));
        }
    }, [formik1.values.role])

    useEffect(() => {
        if (showUpdateModal && idUtilisateurSelect !== "") {
            getAgencesUpdate(debouncedAgenceText);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedAgenceText]);

    return (
        <>
            <div className="container guichet-section">
                <div className="affectation-sec wrap-all">
                    {/* <Link to="#" className="affectation-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Créer un compte
                        </div>
                    </Link> */}
                    <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideGetModal(true)}>
                        <div className="add-new-2" id="add-vehc">
                            <span className="material-symbols-outlined">cloud_download</span> Récupérer données depuis RH
                        </div>
                    </Link>
                    <div className="group-elements without-btn">
                        <div className="voir-elements">
                            {/* <p>Voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <input
                                type="search"
                                value={search}
                                placeholder="Rechercher ..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="apps__button--wrapper">
                                <div className="filter-sw apps__button" onClick={() => showHideFiltre(!showFilters)}>
                                    <span className="material-symbols-outlined">tune</span>
                                    <p>Filter</p>
                                </div>
                                {showFilters && <div className="dropdown__wrapper dropdown__wrapper--fade-in">
                                    <form onSubmit={handleSearch}>
                                        <div className="col">
                                            <div className="row">
                                                <p>Rôle</p>
                                                <select
                                                    id="role"
                                                    name="role"
                                                    value={filters.role || ''}
                                                    onChange={(e) => setFilters({ ...filters, role: e.target.value || '' })}
                                                >
                                                    <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                    {authData?.role != null && authData?.role === "SuperAdmin" && <option value="SuperAdmin">Super admin</option>}
                                                    <option value="Admin">Admin</option>
                                                    <option value="Manager">Responsable de service</option>
                                                    <option value="ResponsableRegional">Responsable régional</option>
                                                    <option value="DirecteurRegional">Directeur régional</option>
                                                    <option value="ChefStation">Chef de station</option>
                                                    <option value="Financier">Financier</option>
                                                    <option value="Approbateur">Approbateur</option>
                                                    <option value="Caissier">Caissier</option>
                                                    <option value="AgentSuivi">Agent de suivi</option>
                                                    <option value="AssistanteDirection">Assistant(e) de direction</option>
                                                    <option value="Employe">Employé</option>
                                                </select>
                                            </div>

                                            <div className="row">
                                                <p>Statut</p>
                                                <select
                                                    id="statut"
                                                    name="statut"
                                                    value={filters.statut || ''}
                                                    onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                                                >
                                                    <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                    <option value="actif">Actif</option>
                                                    <option value="demissionnaire">Démissionnaire</option>
                                                    <option value="suspendu">Suspendu</option>
                                                    <option value="inactif">Inactif</option>
                                                </select>
                                            </div>

                                            <div className="row">
                                                <button className="submit-btn">Filtrer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {filtredUtilisateurs?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Entreprise</th>
                                    <th>Agence</th>
                                    <th>Fonction</th>
                                    <th>Username</th>
                                    <th>Rôle</th>
                                    <th>Statut</th>
                                    <th>Caisse</th>
                                    <th>Région</th>
                                    <th>Modifier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredUtilisateurs.map((row) => {
                                    const isEditable = (((["Approbateur"].includes(row.role) && authData.role === "SuperAdmin") || (["SuperAdmin", "Admin", "Manager", "ResponsableRegional", "DirecteurRegional", "ChefStation", "Financier", "Caissier", "Employe", "AgentSuivi", "AssistanteDirection"].includes(row.role))) && ["actif", "inactif"].includes(row.statut));
                                    const isRegion = ["ResponsableRegional", "DirecteurRegional"].includes(row.role);

                                    return (
                                        <tr key={row.idUtilisateur}>
                                            <td>{row.nom}</td>
                                            <td>{row.prenom}</td>
                                            <td>{row.libelleEntreprise}</td>
                                            <td>{row.libelleAgence}</td>
                                            <td>{row.fonction}</td>
                                            <td>{row.username}</td>
                                            <td>{
                                                row.role === "SuperAdmin" ? <>Super admin</> :
                                                    row.role === "Admin" ? <>Admin</> :
                                                        row.role === "Manager" ? <>Responsable de service</> :
                                                            row.role === "ResponsableRegional" ? <>Responsable régional</> :
                                                                row.role === "DirecteurRegional" ? <>Directeur régional</> :
                                                                    row.role === "ChefStation" ? <>Chef de station</> :
                                                                        row.role === "Financier" ? <>Financier</> :
                                                                            row.role === "Approbateur" ? <>Approbateur</> :
                                                                                row.role === "Caissier" ? <>Caissier</> :
                                                                                    row.role === "AgentSuivi" ? <>Agent de suivi</> :
                                                                                        row.role === "Employe" ? <>Employé</> :
                                                                                            row.role === "AssistanteDirection" ? <>Assistant(e) de direction</> :
                                                                                                null
                                            }</td>
                                            <td>{
                                                row.statut === "actif" ? <>Actif</> :
                                                    row.statut === "inactif" ? <>Inactif</> :
                                                        row.statut === "demissionnaire" ? <>Démissionnaire</> :
                                                            row.statut === "suspendu" ? <>Suspendu</> :
                                                                null
                                            }</td>
                                            <td>{row.libelleCaisse}</td>
                                            <td
                                                className="txt-center"
                                                onClick={isRegion ? () => showHideRegionModal(row.idUtilisateur, row.nom + ' ' + row.prenom) : null}
                                                style={{ cursor: isRegion ? 'pointer' : 'default' }}
                                            >
                                                <span className="material-symbols-outlined" style={{ opacity: isRegion ? '1' : '0.2' }}>apartment</span>
                                            </td>
                                            <td
                                                className="txt-center"
                                                onClick={isEditable ? () => showHideUpdateModal(true, row.idUtilisateur, row.libelleAgence, row.role, row.idCaisse, row.libelleEntreprise) : null}
                                                title={isEditable ? '' : 'Vous ne pouvez pas modifier cet utilisateur'}
                                                style={{ cursor: isRegion ? 'pointer' : 'default' }}
                                            >
                                                <span className="material-symbols-outlined" style={{ opacity: isEditable ? '1' : '0.2' }}>edit</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                            const page = pageNumber - 2 + i;
                            return page <= totalPages && page > 0 && (
                                <Link
                                    key={page}
                                    to="#"
                                    className={pageNumber === page ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Link>
                            );
                        })}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                        >&raquo;</Link>
                        <span> Affichage de {(pageNumber === 1) ? 1 : ((pageNumber - 1) * pageSize + 1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                    </div> */}
                    {filtredUtilisateurs?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>

            {/* popup modifier compte utilisateur */}
            {showUpdateModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Modifier utilisateur</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Nom</label>
                                        <input
                                            type="text"
                                            id="nom"
                                            name="nom"
                                            defaultValue={formik1.values.nom}
                                            readOnly
                                        />
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Prénom</label>
                                        <input
                                            type="text"
                                            id="prenom"
                                            name="prenom"
                                            defaultValue={formik1.values.prenom}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Fonction</label>
                                        <input
                                            type="text"
                                            id="fonction"
                                            name="fonction"
                                            defaultValue={formik1.values.fonction}
                                            readOnly
                                        />
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Statut</label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Username </label>
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            defaultValue={formik1.values.username}
                                            readOnly
                                        />
                                        {/* <input type="text"
                                            id="username"
                                            name="username"
                                            placeholder=""
                                            autoComplete="false"
                                            defaultValue={formik1.values.username}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.username && formik1.errors.username) ? { borderColor: "red" } : null}
                                        />
                                        {formik1.touched.username && formik1.errors.username ? (
                                            <div className="invalid-feedback">{formik1.errors.username}</div>
                                        ) : null} */}
                                    </div>
                                    {/* {(!formik1.values.resetPassword) && <div className="wrap-one-half">
                                        <Link to={`#`} className='act' style={{ fontSize: '1rem' }} onClick={() => handleResetPassword()}>
                                            Changer mot de passe
                                        </Link>
                                    </div>}
                                    {(formik1.values.resetPassword) && <div className="wrap-one-half">
                                        <label >Mot de passe<span className='requiredField'>*</span></label>
                                        <input
                                            type="password"
                                            id="newPassword"
                                            name="newPassword"
                                            style={(formik1.touched.newPassword && formik1.errors.newPassword) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.newPassword}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.newPassword && formik1.errors.newPassword ? (
                                            <div className="invalid-feedback">{formik1.errors.newPassword}</div>
                                        ) : null}
                                    </div>} */}
                                    <div className="wrap-one-half">
                                        <label >Rôle<span className='requiredField'>*</span></label>
                                        <select
                                            id="role"
                                            name="role"
                                            value={formik1.values.role}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            {authData?.role != null && authData?.role === "SuperAdmin" && <option value="SuperAdmin">Super admin</option>}
                                            <option value="Admin">Admin</option>
                                            <option value="Manager">Responsable de service</option>
                                            <option value="ResponsableRegional">Responsable régional</option>
                                            <option value="DirecteurRegional">Directeur régional</option>
                                            <option value="ChefStation">Chef de station</option>
                                            <option value="Financier">Financier</option>
                                            {authData?.role != null && authData?.role === "SuperAdmin" && <option value="Approbateur">Approbateur</option>}
                                            <option value="Caissier">Caissier</option>
                                            <option value="AgentSuivi">Agent de suivi</option>
                                            <option value="Employe">Employé</option>
                                            <option value="AssistanteDirection">Assistant(e) de direction</option>
                                        </select>
                                        {formik1.touched.role && formik1.errors.role ? (
                                            <div className="invalid-feedback">{formik1.errors.role}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Entreprise</label>
                                        <input
                                            type="text"
                                            id="entreprise"
                                            name="entreprise"
                                            defaultValue={formik1.values.entreprise}
                                            readOnly
                                        />
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Agence</label>
                                        <input
                                            type="text"
                                            id="agence"
                                            name="agence"
                                            defaultValue={formik1.values.agence}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                {(formik1.values.role === "Caissier" || formik1.values.role === "ChefStation" || formik1.values.role === "AssistanteDirection") && <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Caisse<span className='requiredField'>*</span></label>
                                        <select
                                            id="idCaisse"
                                            name="idCaisse"
                                            value={formik1.values.idCaisse}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idCaisse && formik1.errors.idCaisse) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {caisses.map((caisse) => (
                                                <option key={caisse.idCaisse} value={caisse.idCaisse}>{caisse.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idCaisse && formik1.errors.idCaisse ? (
                                            <div className="invalid-feedback">{formik1.errors.idCaisse}</div>
                                        ) : null}
                                    </div>
                                </div>}
                                {(formik1.values.role === "ResponsableRegional" || formik1.values.role === "DirecteurRegional") && <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Agences</label>
                                        <textarea
                                            id="agences"
                                            name="agences"
                                            defaultValue={formik1.values.agences}
                                            readOnly
                                            rows={formik1.values.agencesCount > 5 ? 5 : formik1.values.agencesCount}
                                        // style={{ height: '150px', overflow: 'auto' }}
                                        />
                                        {/* <Select
                                            id="idAgencesUpdate"
                                            name="idAgencesUpdate"
                                            value={formik1.values.idAgencesUpdate}
                                            onChange={(option) => { formik1.setFieldValue('idAgencesUpdate', option); }}
                                            options={agences}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idAgence}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredAgenceText(inputValue);
                                            }}
                                            isClearable
                                            isMulti
                                            placeholder=""
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                    width: '25rem',
                                                    height: '6rem',
                                                    overflow: 'hidden',
                                                    overflowY: 'scroll'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        /> */}
                                    </div>
                                </div>}
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Signature </label>
                                        <label className="upload-file">
                                            &nbsp;
                                            <input
                                                ref={pathImageSignatureUpdateRef}
                                                id="pathImageSignature"
                                                name="pathImageSignature"
                                                type="file"
                                                accept="image/*"
                                                onChange={handlePathImageSignatureChangeUpdate}
                                            />
                                        </label>
                                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                        {pathImageSignatureUpdate && <span style={{ fontSize: "1rem" }}>{getImageFileName(pathImageSignatureUpdate)}
                                            <span className="material-symbols-outlined" style={{ fontSize: "1rem", cursor: 'pointer' }} onClick={(e) => { setPathImageSignatureUpdate(null); if (pathImageSignatureUpdateRef.current) { pathImageSignatureUpdateRef.current.value = ''; } }}>delete</span>
                                        </span>}
                                        {pathImageSignatureName && <span style={{ fontSize: "10px" }}>{pathImageSignatureName}
                                            <span className="material-symbols-outlined" style={{ fontSize: "1rem", cursor: 'pointer' }} onClick={(e) => { setPathImageSignatureUpdate(null); setPathImageSignatureName(''); if (pathImageSignatureUpdateRef.current) { pathImageSignatureUpdateRef.current.value = ''; } }}>delete</span>
                                        </span>}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {/* popup pour afficher les agences  */}
            {showRegionModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => { setShowRegionModal(false); setAgencesRegion([]); }}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Région - Agences ({showRegionModalUser})</h2>
                    <div className="popcard-add_inside-form">
                        <form>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <textarea
                                            id="agencesRegion"
                                            name="agencesRegion"
                                            readOnly
                                            defaultValue={agencesRegion?.map(agence => `${agence.libelle}`).join('\n')}
                                            rows={10}
                                            cols={100}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Annuler" className="submit-btn" onClick={() => { setShowRegionModal(false); setAgencesRegion([]); }} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {/* popup recuperer les comptes utilisateurs depuis RH api */}
            {showGetModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" href="#" onClick={() => showHideGetModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form mt-1">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Admin</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="admin"
                                            name="admin"
                                            checked={formik2.values.admin}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Responsables régionaux</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="responsableRegional"
                                            name="responsableRegional"
                                            checked={formik2.values.responsableRegional}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Directeurs régionaux</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="directeurRegional"
                                            name="directeurRegional"
                                            checked={formik2.values.directeurRegional}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Caissiers</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="caissier"
                                            name="caissier"
                                            checked={formik2.values.caissier}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Responsables de service</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="responsableService"
                                            name="responsableService"
                                            checked={formik2.values.responsableService}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Chefs de station</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="chefStation"
                                            name="chefStation"
                                            checked={formik2.values.chefStation}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-row-full">
                                        <label>Financiers</label>
                                        <input
                                            type="checkbox"
                                            className="accnt"
                                            id="financier"
                                            name="financier"
                                            checked={formik2.values.financier}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Valider" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

        </>
    );
}

export default Utilisateurs;
